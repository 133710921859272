import { DatawarehouseSessionInfo } from '../reducers/datawarehouseSession';

export const EPIC_START_DATAWAREHOUSE_SESSION =
  'EPIC_START_DATAWAREHOUSE_SESSION';
export type EpicStartDatawarehouseSessionAction = {
  courseId: number;
  type: typeof EPIC_START_DATAWAREHOUSE_SESSION;
};
export const epicStartDatawarehouseSession = ({
  courseId,
}: Omit<
  EpicStartDatawarehouseSessionAction,
  'type'
>): EpicStartDatawarehouseSessionAction => ({
  courseId,
  type: EPIC_START_DATAWAREHOUSE_SESSION,
});
export const SET_DATAWAREHOUSE_SESSION = 'SET_DATAWAREHOUSE_SESSION';
export type SetDatawarehouseSessionAction = {
  courseId: number;
  session: DatawarehouseSessionInfo | null;
  type: typeof SET_DATAWAREHOUSE_SESSION;
};

export const setDatawarehouseSession = ({
  courseId,
  session,
}: Omit<
  SetDatawarehouseSessionAction,
  'type'
>): SetDatawarehouseSessionAction => ({
  courseId,
  session,
  type: SET_DATAWAREHOUSE_SESSION,
});

export const SET_DATAWAREHOUSE_SESSION_ERROR =
  'SET_DATAWAREHOUSE_SESSION_ERROR';
export type SetDatawarehouseSessionErrorAction = {
  courseId: number;
  error: unknown;
  type: typeof SET_DATAWAREHOUSE_SESSION_ERROR;
};
export const setDatawarehouseSessionError = ({
  courseId,
  error,
}: Omit<
  SetDatawarehouseSessionErrorAction,
  'type'
>): SetDatawarehouseSessionErrorAction => ({
  courseId,
  type: SET_DATAWAREHOUSE_SESSION_ERROR,
  error,
});

export const SET_DATAWAREHOUSE_SESSION_LOADING =
  'SET_DATAWAREHOUSE_SESSION_LOADING';
export type SetDatawarehouseSessionLoadingAction = {
  courseId: number;
  type: typeof SET_DATAWAREHOUSE_SESSION_LOADING;
};

export const setDatawarehouseSessionLoading = ({
  courseId,
}: Omit<
  SetDatawarehouseSessionLoadingAction,
  'type'
>): SetDatawarehouseSessionLoadingAction => ({
  courseId,
  type: SET_DATAWAREHOUSE_SESSION_LOADING,
});

export type DatawarehouseSessionActions =
  | SetDatawarehouseSessionAction
  | SetDatawarehouseSessionErrorAction
  | SetDatawarehouseSessionLoadingAction
  | EpicStartDatawarehouseSessionAction;
