import localstore from 'store';

import { getTreatment } from '../../helpers/featureFlags';
import { sendTagManagerEvent } from '../../helpers/sendTagManagerEvent';
import { STORAGE_KEYS } from '../../helpers/useStorage';
import { Thunk } from '../../interfaces/State';
import { DAILY_XP_GOAL } from '../reducers/streakInfo';
import * as selectors from '../selectors';

export const SHOW_DAILY_STREAK_SCREEN = 'streaks/SHOW_DAILY_STREAK_SCREEN';
export type ShowDailyStreakScreenAction = {
  type: typeof SHOW_DAILY_STREAK_SCREEN;
};
export const showDailyStreakScreen = (): ShowDailyStreakScreenAction => ({
  type: SHOW_DAILY_STREAK_SCREEN,
});

export const HIDE_DAILY_STREAK_SCREEN = 'streaks/HIDE_DAILY_STREAK_SCREEN';
export type HideDailyStreakScreenAction = {
  type: typeof HIDE_DAILY_STREAK_SCREEN;
};
export const hideDailyStreakScreen = (): HideDailyStreakScreenAction => ({
  type: HIDE_DAILY_STREAK_SCREEN,
});

export const checkDailyStreak = (): Thunk => (dispatch, getState) => {
  const streakInfo = selectors.selectStreakInfo(getState());

  const streakUnknownOrGoalNotReached =
    streakInfo.type === 'StreakUnknown' || streakInfo.dailyXp < DAILY_XP_GOAL;

  if (streakUnknownOrGoalNotReached) {
    return;
  }

  // Check if daily streak screen was shown already
  const streakIncrementedAt = Date.parse(streakInfo.incrementedAt ?? '') || 0;

  const lastSeenAtRaw = localstore.get(STORAGE_KEYS.STREAK_MODAL_SEEN);
  const lastSeenAt = parseInt(lastSeenAtRaw || '0', 10) || 0;

  if (
    !streakIncrementedAt ||
    (lastSeenAt && lastSeenAt >= streakIncrementedAt)
  ) {
    return;
  }

  const treatment = getTreatment('wl--streaks-communication');

  if (treatment && treatment !== 'control') {
    sendTagManagerEvent({
      bucket: treatment,
      event: 'bucket_experiment',
      experimentName: 'wl--streaks-communication',
    });
  }

  if (treatment === 'on') {
    // Store seen time and display screen
    localstore.set(STORAGE_KEYS.STREAK_MODAL_SEEN, `${streakInfo.deadline}`);

    dispatch(showDailyStreakScreen());
  }
};

export type StreakActions =
  | ShowDailyStreakScreenAction
  | HideDailyStreakScreenAction;
