import { mobileApi } from '../../helpers/api/clients';
import * as selectors from '../selectors';

export const MOBILE_POPUP_LINK_SENT = 'mobilePopup/MOBILE_POPUP_LINK_SENT';
export const mobilePopupLinkSent = () => ({
  type: MOBILE_POPUP_LINK_SENT,
});

export const MOBILE_POPUP_LINK_FAILED = 'mobilePopup/MOBILE_POPUP_LINK_FAILED';
export const mobilePopupLinkFailed = () => ({
  type: MOBILE_POPUP_LINK_FAILED,
});

export const MOBILE_POPUP_LINK_SENDING =
  'mobilePopup/MOBILE_POPUP_LINK_SENDING';
export const mobilePopupLinkSending = () => ({
  type: MOBILE_POPUP_LINK_SENDING,
});

export const sendMobilePopupLink = () => (dispatch: any, getState: any) => {
  dispatch(mobilePopupLinkSending());

  const state = getState();
  const courseId = selectors.selectCourse(state).get('id');
  const chapterId = selectors.selectChapterId(state);
  const exerciseId = selectors.selectExercise(state).get('id');

  return mobileApi
    .sendMobilePopupLink({ courseId, chapterId, exerciseId })
    .then(() => dispatch(mobilePopupLinkSent()))
    .catch(() => dispatch(mobilePopupLinkFailed()));
};
