export const UPDATE_LOCATION = 'location/UPDATE_LOCATION';
type UpdateLocationAction = {
  pathname: unknown;
  query: unknown;
  type: typeof UPDATE_LOCATION;
};
export const updateLocation = ({
  pathname,
  query,
}: Omit<UpdateLocationAction, 'type'>): UpdateLocationAction => ({
  type: UPDATE_LOCATION,
  pathname,
  query,
});

export const EPIC_UPDATE_META_TAGS = 'location/EPIC_UPDATE_META_TAGS';
export const epicUpdateMetaTags = () => ({
  type: 'location/EPIC_UPDATE_META_TAGS',
});

export const UPDATE_MID_LOCATION = 'location/UPDATE_MID_LOCATION';
export const updateMidLocation = ({ pathname, query }: any) => ({
  type: UPDATE_MID_LOCATION,
  pathname,
  query,
});

export type LocationActions = UpdateLocationAction;
