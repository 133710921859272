export const UPDATE_CHAPTER = 'chapter/UPDATE_CHAPTER';
export const updateChapter = ({ chapterRef, chapters }: any) => ({
  type: UPDATE_CHAPTER,
  chapterRef,
  chapters,
});

export const SHOULD_SHOW_WINDOW = 'chapter/SHOULD_SHOW_WINDOW';
export const shouldShowWindow = ({
  isCourseEndReached,
  meta,
  type,
  url,
}: any) => ({
  type: SHOULD_SHOW_WINDOW,
  windowType: type,
  url,
  meta,
  isCourseEndReached,
});
