import { MultiplexerJsonRpcSessionManager } from '../../helpers/jsonRpc/multiplexerJsonRpcSessionManager';

export const SET_MUX_SESSION_MANAGER = 'SET_MUX_SESSION_MANAGER';
export type SetMuxSessionManagerAction = {
  muxSessionManager: MultiplexerJsonRpcSessionManager;
  type: typeof SET_MUX_SESSION_MANAGER;
};
export const setMuxSessionManager = (
  muxSessionManager: MultiplexerJsonRpcSessionManager,
): SetMuxSessionManagerAction => ({
  muxSessionManager,
  type: SET_MUX_SESSION_MANAGER,
});

export type BackendSessionJsonRpcActions = SetMuxSessionManagerAction;
