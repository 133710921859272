import { Record as ImmutableRecord } from 'immutable';
// This is the only place where it is okay!
// eslint-disable-next-line no-restricted-imports
import {
  useDispatch as originalUseDispatch,
  useSelector as originalUseSelector,
} from 'react-redux';

import { Action } from '../redux/actions';
import { BackendSessionState } from '../redux/reducers/backendSession';
import { BackendSessionJsonRpcState } from '../redux/reducers/backendSessionJsonRpc';
import { BootReducerState } from '../redux/reducers/boot';
import { CodeExplanationState } from '../redux/reducers/codeExplanation';
import { ContentAuthorizationState } from '../redux/reducers/contentAuthorization';
import { DatawarehouseSessionState } from '../redux/reducers/datawarehouseSession';
import { ImagesState } from '../redux/reducers/images';
import { LearningModeState } from '../redux/reducers/learningMode';
import { LearningRecapState } from '../redux/reducers/learningRecap';
import { NotesState } from '../redux/reducers/notes';
import { OnboardingMilestonesState } from '../redux/reducers/onboardingMilestones';
import { OutputState } from '../redux/reducers/output';
import { PreFetchedDataState } from '../redux/reducers/preFetchedData';
import { StreakInfoState } from '../redux/reducers/streakInfo';
import { StreakScreenState } from '../redux/reducers/streakScreen';

export type StateShape = {
  backendSession: BackendSessionState;
  backendSessionJsonRpc: BackendSessionJsonRpcState;
  boot: BootReducerState;
  chapter: unknown;
  codeExplanation: CodeExplanationState;
  contentAuthorization: ContentAuthorizationState;
  course: unknown;
  datawarehouseSession: DatawarehouseSessionState;
  exercises: unknown;
  images: ImagesState;
  learningMode: LearningModeState;
  learningRecap: LearningRecapState;
  location: unknown;
  mobilePopup: unknown;
  notes: NotesState;
  onboardingMilestones: OnboardingMilestonesState;
  output: OutputState;
  preFetchedData: PreFetchedDataState;
  settings: unknown;
  streakInfo: StreakInfoState;
  streakScreen: StreakScreenState;
  systemStatus: unknown;
  user: unknown;
};
export type State = ImmutableRecord.Instance<StateShape>;

export type GetState = () => State;
export type Thunk = (dispatch: Dispatch, getState: GetState) => void;
export type Dispatch = (action: Action | Thunk) => Promise<void>;
export type Selector<Selected> = (state: State) => Selected;
export type Reducer<State> = (
  state: State | undefined,
  action: Action,
) => State;

export const useDispatch: () => Dispatch = originalUseDispatch;
export const useSelector: <TSelected = unknown>(
  selector: Selector<TSelected>,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) => TSelected = originalUseSelector;
