export const EPIC_GOT_WINDOW_RESPONSE = 'window/EPIC_GOT_WINDOW_RESPONSE';
export const epicGotWindowResponse = () => ({
  type: EPIC_GOT_WINDOW_RESPONSE,
});

export const SHOW_WINDOW = 'window/SHOW_WINDOW';
export const showWindow = ({ show }: any) => ({ type: SHOW_WINDOW, show });

export const REMOVE_WINDOW = 'window/REMOVE_WINDOW';
export const removeWindow = () => ({ type: REMOVE_WINDOW });
