import { Record as ImmutableRecord } from 'immutable';
import filter from 'lodash/filter';
import startsWith from 'lodash/startsWith';
import {
  applyMiddleware,
  compose,
  createStore as originalCreateStore,
} from 'redux';
import { combineReducers } from 'redux-immutable';
import { createEpicMiddleware } from 'redux-observable';
import reduxThunk from 'redux-thunk';

import config from '../config';
import rootEpic from '../helpers/epics';
import { StateShape } from '../interfaces/State';

import { Action } from './actions';
import reducers from './reducers';
import { initialState as initialBackendSessionState } from './reducers/backendSession';

export const StateRecord = ImmutableRecord<StateShape>(
  {
    backendSession: initialBackendSessionState,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    backendSessionJsonRpc: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    boot: undefined,
    chapter: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    codeExplanation: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    contentAuthorization: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    datawarehouseSession: undefined,
    course: undefined,
    exercises: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    learningMode: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    learningRecap: undefined,
    location: undefined,
    mobilePopup: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    onboardingMilestones: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    notes: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    output: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    preFetchedData: undefined,
    settings: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    streakInfo: undefined,
    // @ts-expect-error Set to undefined here so default initialState can be set by the reducer
    streakScreen: undefined,
    systemStatus: undefined,
    user: undefined,
    images: null,
  },
  'StateRecord',
);

const createStore = ({ history, initialState, middlewares }: any) => {
  const composeEnhancers =
    !config.isProductionDomain() &&
    !config.isServerSideRendering &&
    // @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  // @ts-expect-error ts-migrate(6133) FIXME: 'fn' is declared but its value is never read.
  const classicMiddlewares = filter(middlewares, (fn, name) =>
    startsWith(name, 'middleware'),
  );

  const storeEnhancer = composeEnhancers(
    applyMiddleware(
      // This needs to be first!
      reduxThunk,
      ...classicMiddlewares,
      // @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type 'Action'.
      createEpicMiddleware(rootEpic({ history, middlewares })),
    ),
  );

  return originalCreateStore(
    // @ts-expect-error We need to type StateShape correctly to drop this
    combineReducers<StateShape, Action>(reducers, StateRecord),
    initialState,
    storeEnhancer,
  );
};

export default createStore;
